.c-footer {
	width: 100%;
	font-size: 85%;
	font-weight: 300;
}

.c-footer-image-container {
	position: relative;
	&::after {
		content: ""; // ::before and ::after both require content
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 50%;
		background-image: linear-gradient(0deg, rgba(255,255,255,0), #ffffff);
		opacity: 1;
	}
	.footer-img {
		min-height: 400px;
		object-fit: cover;
		width: 100%;
	}
}