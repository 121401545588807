$sky-blue: #59D2FE;
$sky-blue--mid: #9BE4FE;
$sky-blue--light: #DEF6FF;

.c-accordion__panel[aria-hidden=true] {
  display: none;
}

.c-accordion__header {
  display: block;
  width: 100%;
  text-align: left;
  background-color: $sky-blue--light;
  border: 1px solid $sky-blue;
  border-width: 1px 0px 1px 0px;
  display: flex;
  justify-content: space-between;
  padding: 12px 12px;
  font-size: 85%;
}

.c-accordion__title {
  margin-bottom: 0;
}

.c-accordion__panel {
  padding: 24px 12px 12px 12px;
}

/* title opened */
.c-accordion__header[aria-expanded="true"]:after {
  content: "- ";
}
/* title closed */
.c-accordion__header[aria-expanded="false"]:after {
  content: "+ ";
}