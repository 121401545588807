//
// Type
//
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');


/* import only the necessary Bootstrap files */
@import "bootstrap/scss/functions"; 
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

/* -------begin customization-------- *//* change the primary theme color to red */

//
// Type
//

@font-face {
    font-family: 'YoungSerif';
    src:url('../fonts/YoungSerif-Regular.woff2'),
    	url('../fonts/YoungSerif-Regular.woff');
    font-weight: normal;
    font-style: normal;
}

$font-family-base: "Inter", "system-ui";
$headings-font-family: "YoungSerif";

.font-body {
  font-family: $font-family-base;
}

.font-heading {
  font-family: $headings-font-family;
}

//
// Colour
//

$dark-green: #036016;
$dark-green--mid: #68A073;
$dark-green--light: #CDDFD0;

$sky-blue: #59D2FE;
$sky-blue--mid: #9BE4FE;
$sky-blue--light: #DEF6FF;

$violet: #231651;
$violet--mid: #7B7397;
$violet--light: #D3D0DC;

$eco-green: #8FF7A7;
$eco-green--mid: #BCFACA;
$eco-green--light: #E9FDED;

$off-white: #FAF9F9;
$off-black: #071013;

$colors: (
  "dark-green": $dark-green,
  "sky-blue": $sky-blue,
  "violet": $violet,
  "eco-green": $eco-green,
  "dark-green--mid": $dark-green--mid,
  "sky-blue--mid": $sky-blue--mid,
  "violet--mid": $violet--mid,
  "eco-green--mid": $eco-green--mid,
  "dark-green--light": $dark-green--light,
  "sky-blue--light": $sky-blue--light,
  "violet--light": $violet--light,
  "eco-green--light": $eco-green--light,
  "off-black": $off-black,
  "off-white": $off-white
);

$theme-colors: (
	"dark-green": $dark-green,
	"sky-blue": $sky-blue,
	"violet": $violet,
	"eco-green": $eco-green,
  "dark-green--mid": $dark-green--mid,
  "sky-blue--mid": $sky-blue--mid,
  "violet--mid": $violet--mid,
  "eco-green--mid": $eco-green--mid,
  "dark-green--light": $dark-green--light,
  "sky-blue--light": $sky-blue--light,
  "violet--light": $violet--light,
  "eco-green--light": $eco-green--light,
  "off-black": $off-black,
  "off-white": $off-white
);

// Changes to bootstrap variables

// More margin under headings
$headings-margin-bottom: 1em;

// Increase button font-weight
$btn-font-weight: 400;
// Decrease button font-size
$btn-font-size: 90%;
$btn-font-size-lg: 100%;

// Shadow classes

$soft-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
$med-shadow: rgba(0, 0, 0, 0.48) 0px 5px 8px;

.box-shadow-soft {
  box-shadow: $soft-shadow;
}

.box-shadow-med {
  box-shadow: $med-shadow;
}

.text-shadow-soft {
  text-shadow: $soft-shadow;
}

.text-shadow-med {
  text-shadow: $med-shadow;
}


/* -------end customization-------- *//* finally, import Bootstrap to set the changes! */
@import "bootstrap/scss/bootstrap";

@import "header";
@import "footer";
@import "masthead";
@import "homepage";
@import "accordion";
