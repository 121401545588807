#introduction {
	position: relative;
	z-index: 1020;
	img {
		max-width: 100%;
		@media (min-width: 992px) {
			position: absolute;
		    top: -6em;
		}
	}
}

#what-causes {
	.card {
	}
}

/**
 * Enable smooth scrolling on the whole document
 */
html {
	scroll-behavior: smooth;
}