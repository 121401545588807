.c-header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background-color: transparentize(#071013, 0.95);
	box-shadow: 0;
	z-index: 10000;
	padding: 12px 0;
	transition: all ease 650ms;
	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}
	a {
		font-weight: 600;
	}
}

// If header has scrolled, background should be white
.c-header.state--has-scrolled {
	background-color: transparentize(#ffffff, 0.15);
	box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.c-header__logo {
	width: 15vw;
	max-width: 220px;
	height: auto;
}

// Reduce font size of header elements
.c-header a, .c-header .btn {
	font-size: 90%;
}

// Smaller logo at mobile
@media (max-width: 768px) {
	.c-header__logo {
		width: 120px;
	}
}

// No button at tiny widths
@media (max-width: 375px) {
	.c-header .btn {
		display: none;
	}
}