#gl {
	width: 100vw;
	height: 100vh;
	max-width: 100%;
	left: 0;
	top: 0;
	position: absolute;
}

canvas {
	display: block;
}

/* Page Loader */
.js .loading::before {
	content: '';
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	content: '';
	position: fixed;
	z-index: 100;
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	pointer-events: none;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;
}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5,0.5,1);
	}
}

.frame {
	padding: 3rem 5vw;
	text-align: center;
	position: relative;
	z-index: 1000;
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	&::after {
		content: ""; // ::before and ::after both require content
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 50%;
		background-image: linear-gradient(0deg, #000000, transparent);
		opacity: .35;
	}
}

@media screen and (min-width: 53em) {
	.message {
		display: none;
	}
	.frame {
		position: relative;
		text-align: left;
		z-index: 1000;
		top: 0;
		left: 0;
		align-content: space-between;
		width: 100%;
		max-width: none;
		height: 100vh;
		padding: 2rem 3rem;
		pointer-events: none;
	}
	.frame a {
		pointer-events: auto;
	}
}

$soft-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
$med-shadow: rgba(0, 0, 0, 0.48) 0px 5px 8px;

.c-masthead {
	position: relative;
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.c-masthead__text-container {
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.c-masthead__text {
	position: absolute;
    // bottom: 5vh;
    z-index: 1001;
}

